import React, { Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import SiteConfig from "./data/Site";
import { generatePath } from "./Common";
import Loading from "./templates/Loading";
import ScrollToTop from "./templates/ScrollToTop";
//const PWAUpdate = lazy(() => import("./ctemplates/PWAUpdate"));
const NavBar = lazy(() => import("./ctemplates/NavBar"));
const Footer = lazy(() => import("./templates/Footer"));
const HomePage = lazy(() => import("./ctemplates/Home"));
const Blog = lazy(() => import("./templates/Blog"));
const BlogList = lazy(() => import("./templates/BlogList"));
const BlogReader = lazy(() => import("./templates/BlogReader"));
const About = lazy(() => import("./ctemplates/About"));
//const Search = lazy(() => import("./templates/Search"));
const PageNotFound = lazy(() => import("./templates/PageNotFound"));

const pageTemplates = {
  home: HomePage,
  about: About,
};

const generateRoutes = (pages, parentInfo) => {
  return pages.map((page, i) => {
    var path = parentInfo
      ? parentInfo.path + generatePath(page)
      : generatePath(page);

    const metaInfo = {
      title: parentInfo
        ? page.title + " | " + parentInfo.metaInfo.title
        : path !== "" && path !== "/"
        ? page.title + " | " + SiteConfig.brand
        : SiteConfig.brand,
      description: page.description,
      canonical: window.location.protocol + "//" + window.location.host + path,
    };

    const pageHeader = {
      title: page.title,
      breadCrumb: parentInfo
        ? [
            ...parentInfo.pageHeader.breadCrumb,
            {
              page: page.title,
              path: path,
            },
          ]
        : [
            {
              page: SiteConfig.pages[0].title,
              path: "/",
            },
            {
              page: page.title,
              path: path,
            },
          ],
      hideHeader: page.hideHeader ? page.hideHeader : false,
    };
    if (path === "/") {
      pageHeader.breadCrumb = [];
    }

    var routes = [];

    if (page.type === "blog") {
      routes.push(
        <Route key={"route-" + i} path={path} element={<Blog />}>
          <Route
            key={"route-" + i + "-2"}
            path=""
            element={
              <BlogList
                key={"page-" + i + "-2"}
                metaInfo={metaInfo}
                header={pageHeader}
                content={page.content}
                blogSource={page.blogSource}
              />
            }
          />
          <Route
            key={"route-" + i + "-1"}
            path=":id"
            element={
              <BlogReader
                key={"page-" + i + "-1"}
                metaInfo={metaInfo}
                header={pageHeader}
                blogSource={page.blogSource}
              />
            }
          />
          <Route
            key={"route-" + i + "-2"}
            path="page/:pageNo"
            element={
              <BlogList
                key={"page-" + i + "-2"}
                metaInfo={metaInfo}
                header={pageHeader}
                content={page.content}
                blogSource={page.blogSource}
              />
            }
          />
        </Route>
      );
    } else {
      const PageTemplateToRender = pageTemplates[page.type];
      routes.push(
        <Route
          key={"route-" + i}
          path={path}
          element={
            <PageTemplateToRender
              key={"page-" + i}
              metaInfo={metaInfo}
              header={pageHeader}
              content={page.content}
            />
          }
        />
      );
    }

    if (page.nestedPages && page.nestedPages.length > 0) {
      const parentInfo = {
        path,
        metaInfo,
        pageHeader,
      };
      routes = [...generateRoutes(page.nestedPages, parentInfo), ...routes];
    }

    return routes;
  });
};

export default function App() {
  const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const location = useLocation();

  if (gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId);
    ReactGA.send(location.pathname + location.search);
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send(location.pathname); // Record a pageview for the given page
  }

  const routes = generateRoutes(SiteConfig.pages, undefined);

  return (
      <Suspense fallback={<Loading />}>
        <NavBar />
        <ScrollToTop />
        <main style={{ minHeight: "768px" }}>
          <Routes>
            {routes}
            {/*<Route path="/search/:query" element={<Search />} />
                <Route path="/search" element={<Search />} />*/}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
        <Footer />
        {/*<PWAUpdate />*/}
      </Suspense>
  );
}
