// Generate clean URL paths (slugs) for pages
export function generatePath(page) {
  var path = page.path;
  if (!page.path) {
    const titleWordArray = page.title.split(" ");
    path = "/" + titleWordArray.slice(0, 10).join("-").toLowerCase();
  }

  return path;
}

export function formatDate(stringDate) {
  const date = new Date(stringDate);
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date);

  return `${day} ${month} ${year}`;
}

const Common = { generatePath, formatDate };

export default Common;
