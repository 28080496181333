var brand = "Tan Chang Kiat";
var productionDomain = "https://changkiat.com";
var searchEngineCrawl = true;
var useServiceWorker = false;
var copyright = "&copy; Tan Chang Kiat " + new Date().getFullYear();

var pages = [
  {
    id: 1,
    type: "home",
    title: "Home",
    description: "An aspiring Tech Lead & Solution Architect",
    path: "/",
    content: ``,
    lastMod: "2023-02-28",
  },
  {
    id: 2,
    type: "blog",
    title: "Blog",
    description: "Read articles about web development and cloud computing",
    content: ``,
    lastMod: "2021-02-19",
    blogSource: "Blog.js",
  },
  {
    id: 3,
    type: "about",
    title: "About",
    description: "Learn more about Chang Kiat",
    content: ``,
    lastMod: "2023-02-28",
  },
];

module.exports = {
  brand,
  productionDomain,
  searchEngineCrawl,
  useServiceWorker,
  copyright,
  pages,
};
