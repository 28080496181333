import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import "./layout.css";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import SiteConfig from "./data/Site";

const root = createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Router>
      <App />
    </Router>
  </HelmetProvider>
);

if (SiteConfig.useServiceWorker) {
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      //document.getElementsByClassName("pwa-update")[0].style.display = "block";
    },
  });
} else {
  serviceWorkerRegistration.unregister();
}
